import React, { Component } from 'react'
import { connect } from 'react-redux'
import { clearAllAnswers, setCurrentQuestion, initSteps, sendGA } from './../../questions/shared/state/actions'

import BNZScamSavvyStepOne from './BNZScamSavvyStepOne'
import BNZScamSavvyStepTwo from './BNZScamSavvyStepTwo'
import BNZScamSavvyStepThree from './BNZScamSavvyStepThree'
import BNZScamSavvyStepFour from './BNZScamSavvyStepFour'
import BNZScamSavvyStepFive from './BNZScamSavvyStepFive'
import BNZScamSavvyStepLoading from './BNZScamSavvyStepLoading'
import BNZScamSavvyDownload from './download/BNZScamSavvyDownload'
import BNZScamSavvyVideo from './video/BNZScamSavvyVideo'
import BNZScamSavvyStepOneAndAHalf from './BNZScamSavvyStepOneAndAHalf'

class BNZScamSavvyStepZero extends Component {
  constructor(props) {
    super(props)
    this.state = {
      downloadModal: false,
      videoModal: false
    }
    this.onStart = this.onStart.bind(this)
    this.onClickDownload = this.onClickDownload.bind(this)
    this.onDownloadClose = this.onDownloadClose.bind(this)
    this.onClickVideo = this.onClickVideo.bind(this)
    this.onVideoClose = this.onVideoClose.bind(this)
  }

  componentDidMount() {
    this.props.dispatch(clearAllAnswers())
    this.props.dispatch(setCurrentQuestion({}))
  }

  componentDidUpdate(prevProps) {
    if (this.props.modal !== prevProps.modal && this.props.modal === 'download') {
      this.showDownload()
    }
  }

  onStart() {
    this.props.dispatch(sendGA('Click', 'BusinessLanding', 'StartQuiz'))
    this.props.dispatch(initSteps())
  }

  showDownload() {
    this.setState({downloadModal: true})
    this.props.dispatch(sendGA('BusinessDownload', 'BusinessLanding', 'ModalShow'))
  }

  onClickDownload() {
    this.setState({downloadModal: true})
    this.props.dispatch(sendGA('Click', 'BusinessLanding', 'Download'))
  }

  onDownloadClose() {
    this.setState({downloadModal: false})
    this.props.dispatch(sendGA('Click', 'BusinessDownload', 'Close'))
  }

  onClickVideo() {
    this.setState({videoModal: true})
    this.props.dispatch(sendGA('Click', 'BusinessLanding', 'Video'))
  }

  onVideoClose() {
    this.setState({videoModal: false})
    this.props.dispatch(sendGA('Click', 'Video', 'Close'))
  }

  render() {
    const { downloadModal, videoModal } = this.state
    let content = <div />
    let components = (
      <div className="animated fadeIn col-sm-12 p-0">
        <BNZScamSavvyStepOne onClickStart={this.onStart}/>
        <BNZScamSavvyStepOneAndAHalf onClickDownload={this.onClickDownload}/>
        <BNZScamSavvyStepTwo />
        <BNZScamSavvyStepThree />
        <BNZScamSavvyStepFour onClickStart={this.onStart}  />
        <BNZScamSavvyStepFive onClickVideo={this.onClickVideo} />
      </div>
    )

    if (this.props.answerStore.started)
      components = <BNZScamSavvyStepLoading questionData={this.props.questionData}  />

    content = (
      <div className="container-fluid w-100">
        <div className="row">
          { downloadModal && <BNZScamSavvyDownload onDownloadClose={this.onDownloadClose} /> }
          { videoModal && <BNZScamSavvyVideo onVideoClose={this.onVideoClose} /> }
          {components}
        </div>
      </div>
    )
    
    return content
  }
}
export default connect(state => ({
  answerStore: state['bnz-scam-savvy-answer-list']
}))(BNZScamSavvyStepZero)
